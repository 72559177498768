import Chart from 'react-apexcharts'
import React, { useEffect } from 'react'

const getOptions = (min, max) => ({
  legend: {
    show: false,
  },
  stroke: {
    width: 0,
  },
  plotOptions: {
    heatmap: {
      colorScale: {
        ranges: [
          {
            from: min,
            to: max * 0.2,
            color: '#00ff1e',
            name: 'low',
          },
          {
            from: max * 0.2,
            to: max * 0.4,
            color: '#bcff00',
            name: 'low-medium',
          },
          {
            from: max * 0.4,
            to: max * 0.6,
            color: '#ffeb00',
            name: 'medium',
          },
          {
            from: max * 0.6,
            to: max * 0.8,
            color: '#ff9a00',
            name: 'medium-high',
          },
          {
            from: max * 0.8,
            to: max,
            color: '#ff0000',
            name: 'high',
          },
        ],
      },
    },
  },
})

const getSeries = (matrix) => {
  let series = []

  for (let row = 0; row < matrix.length; row++) {
    let data = []

    for (let col = 0; col < matrix.length; col++) {
      data.push({ x: col, y: matrix[row][col] })
    }

    series.unshift({ name: matrix.length - row, data: data })
  }

  return series
}

const calculateCenterOfMass = (matrix) => {
  const m1 = Number(matrix[0][0])
  const m2 = Number(matrix[0][1])
  const m3 = Number(matrix[1][0])
  const m4 = Number(matrix[1][1])

  const M = m1 + m2 + m3 + m4

  const x = (m1 * 35 + m2 * 260 + m3 * 35 + m4 * 260) / M
  const y = (m1 * 35 + m2 * 35 + m3 * 245 + m4 * 245) / M

  return { x, y }
}

const HeatMapSensor = ({ matrix, min, max }) => {
  const options = getOptions(min, max)
  const series = getSeries(matrix)

  const heatmapRef = React.createRef()
  const massCenterRef = React.createRef()

  useEffect(() => {
    const drawCenterOfMass = () => {
      const heatmap = heatmapRef.current
      const massCenter = massCenterRef.current

      massCenter.style.position = 'absolute'
      massCenter.style.left = heatmap.offsetLeft
      massCenter.style.top = heatmap.offsetTop

      const { x, y } = calculateCenterOfMass(matrix)
      const ctx = massCenter.getContext('2d')

      ctx.clearRect(0, 0, 280, 280)

      ctx.beginPath()
      ctx.arc(x, y, 5, 0, 2 * Math.PI, false)
      ctx.closePath()

      ctx.lineWidth = 5
      ctx.strokeStyle = '#006064'
      ctx.fillStyle = '#4D9699'
      ctx.stroke()
      ctx.fill()

      // Desenha o círculo interno
      ctx.beginPath()
      ctx.arc(150, 140, 50, 0, 2 * Math.PI, false)
      ctx.closePath()

      ctx.lineWidth = 2
      ctx.strokeStyle = '#006064'
      ctx.fillStyle = 'transparent'

      ctx.stroke()
      ctx.fill()

      // Desenha o círculo externo
      ctx.beginPath()
      ctx.arc(150, 140, 100, 0, 2 * Math.PI, false)
      ctx.closePath()

      ctx.lineWidth = 2
      ctx.strokeStyle = '#006064'
      ctx.fillStyle = 'transparent'

      ctx.stroke()
      ctx.fill()
    }
    const isOrderTwoMatrix =
      matrix.length === 2 && matrix[0].length === 2 && matrix[1].length === 2

    if (isOrderTwoMatrix) drawCenterOfMass()
  }, [heatmapRef, massCenterRef, matrix])

  return (
    <div style={{ position: 'relative' }}>
      <Chart
        ref={heatmapRef}
        options={options}
        series={series}
        type="heatmap"
        height="280"
        width="280"
      />
      <canvas
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 9 }}
        ref={massCenterRef}
        width="280px"
        height="280px"
      ></canvas>
    </div>
  )
}

export default HeatMapSensor

